export const environment = {
  production: true,
  env: 'prod-uswest2',
  host: 'eandlearning.com',

  hedwigUrl: 'https://hedwig.eandlearning.com',
  saharaUrl: 'https://my.eandlearning.com',
  instructorMarketUrl: 'https://instructors.eandlearning.com',
  serviceMarketUrl: 'https://instructors.eandlearning.com',
  integrationFramework: 'https://integration.eandlearning.com',
  amberBaseUrl: 'https://amber.eandlearning.com',
  amberUrl: 'https://amber.eandlearning.com/api',
  bouncerUrl: 'https://account.eandlearning.com',
  sphinxUrl: 'https://downloads.eandlearning.com',

  welcomePageCampaignKey: 'product-marketplace.welcome-page.campaign',
  welcomePagePromotionKey: 'product-marketplace.welcome-page.promotion',

  aws: {
    key: 'AKIAWLQHGLXHLW7ANZOM',
    s3Url: 'https://downloads.eandlearning.com/proxy/service/jwt',
    cdnBucket: 'leap-prod-uswest2-cdn',
    uploadsBucket: 'leap-prod-uswest2-product-marketplace-uploads',
  },

  adyen: {
    clientKey: 'test_ZVI4LE36HVE2FCXGOAHXRIADDA7XNF5K'
  },
  okta: {
    issuer: 'https://edcast.okta.com/oauth2/ausew5lg3ep73Kkol2p7',
    clientId: '00a-etisalatprod-env',
    prefix: 'etisalatprod'
  }
};
